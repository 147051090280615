<template>
  <v-footer id="dashboard-core-footer" />
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    // data: () => {},
  }
</script>
